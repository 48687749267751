<template>
  <div class="homePageWrap container">
    <header>
      <el-row class="hedaer-Bg" type="flex">
        <el-col :span="12">
          <div class="grid-content header-left-content">
            <div class="logo-wrap">
              <img src="../../assets/image/homeImg/logo.png" />
            </div>
            <div class="app-title" @click="$router.push('/home')">
              在线培训平台
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content header-right-content">
            <div @click="pageJumpLogin" v-if="userName == ''">登录</div>
            <div v-else class="userName-wrap">
              <el-dropdown placement="bottom">
                <span class="el-dropdown-link">
                  学员{{ userName
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="$router.push('/accountSafe')">账号安全</el-dropdown-item>
                  <el-dropdown-item @click.native="$router.push('/login')">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-col>
      </el-row>
      <navTab></navTab>
    </header>
    <el-main class="main-content">
      <div class='navLocation'>账号安全>修改密码</div>
      <el-form class='formBox' label-width="80px" :model="formLabelAlign" :rules="rules" ref="ruleForm">
        <el-form-item label="原始密码:">
          <el-input type="password" v-model="formLabelAlign.oldPs" placeholder='请输入原始密码' show-password></el-input>
        </el-form-item>
        <el-form-item label="新密码:">
          <el-input type="password" v-model="formLabelAlign.newPs" placeholder='请输入新密码' show-password></el-input>
        </el-form-item>
        <el-form-item label="确认密码:">
          <el-input type="password" v-model="formLabelAlign.confirmPs" placeholder='请再次确认新密码' show-password></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submiteditPassword">保存</el-button>
        </el-form-item>
      </el-form>
    </el-main>
    <footer-com></footer-com>
  </div>
</template>

<script>
import footerCom from '@/components/footer.vue'
import navTab from '@/components/navTab.vue'
export default {
  name: 'homePage',
  components: {
    footerCom,
    navTab
  },
  watch: {},
  data () {
    return {
      userName: '',
      formLabelAlign: {
        oldPs: '',
        newPs: '',
        confirmPs: ''
      },
      rules: {
        oldPs: [
          { required: true, message: '此项不能为空', trigger: 'blur' },
        ],
        newPs: [
          { required: true, message: '此项不能为空', trigger: 'blur' },
        ],
        confirmPs: [
          { required: true, message: '此项不能为空', trigger: 'blur' },
        ],
      }
    }
  },
  methods: {
    pageJumpLogin () {
      console.log('111111')
      this.$router.push('/login')
    },

    // 获取local中用户详细信息
    getUserInfo () {
      let userinfo = localStorage.getItem('studentInfo')
        ? JSON.parse(localStorage.getItem('studentInfo'))
        : ''
      console.log(userinfo)
      if (userinfo) {
        let { stuName } = userinfo
        this.userName = stuName
      }
    },
    updatePassword () {
      this.$router.push('/updatePassword')
    },
    submiteditPassword () {
      this.$refs['ruleForm'].validate((valid) => {
        console.log(valid)
        if (valid) {
          this.$axios.post(this.$apiUrl.editPassword, {
            oldPassword: this.formLabelAlign.oldPs,
            password: this.formLabelAlign.newPs,
            passwordConform: this.formLabelAlign.confirmPs
          }).then(res => {
            if (res.data.success) {
              this.$message({
                message: '修改成功,请重新登录！',
                type: 'success'
              })
              setTimeout(() => {
                this.$router.push('/login')
              }, 1000)
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          return false
        }
      })
    }
  },
  mounted () {
    this.getUserInfo()
  },
}
</script>

<style scoped lang="scss">
  .container {
    position: relative;
    min-height: 100vh;
    background-color: #fbfbff;
    header {
      background: url("../../assets/image/homeImg/headerBg.png");
      background-size: 100% 100%;
    }

    .hedaer-Bg {
      /* background-color:#6a8dea; */
      background-size: 100% 100%;
      height: 42px;
      width: 1440px;
      margin: 0 auto;
      line-height: 42px;
      padding: 0 100px;

    .header-left-content {
      font-size: 20px;
      color: white;
      font-weight: 800;
      display: flex;
      justify-content: start;

      .app-title {
        cursor: pointer;
      }

      .logo-wrap {
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 33px;
        }
      }
    }

    .header-right-content {
      font-size: 18px;
      color: white;
      height: 42px;
      display: flex;
      justify-content: end;
      align-items: center;

      .userName-wrap {
        height: 30px;
        line-height: 30px;
        align-items: center;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }

      ::v-deep .el-dropdown {
        font-size: 14px;
        color: #4774df;
        background-color: #fff;
        height: 30px;
        line-height: 30px;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        padding: 0px 10px;
      }
    }
    }
  }

  .main-content {
    width: 1240px;
    padding-bottom: 188px;
    margin:  0 auto 0;
    padding: 30px;
    .navLocation{
      padding-top: 30px;
      font-size: 18px;
    }
    .formBox{
      width: 500px;
      padding-top:50px;
      margin: 0 auto;
    }
  }

  header {
    padding: 0px;
  }

  ::v-deep .el-main {
    padding: 0px;
    padding-bottom: 188px;
  }

  ::v-deep .el-dropdown-menu__item {
    font-size: 16px;
    line-height: 20px;
  }
  ::v-deep .el-carousel__container{
    height: 500px;
  }
</style>
